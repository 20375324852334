import {
  doc,
  collection,
  getFirestore,
  setDoc,
  limit,
  onSnapshot,
  query,
  orderBy,
  where,
  getCountFromServer,
  writeBatch,
} from 'firebase/firestore';
import { ConversationDoc, MessageDoc } from '@og-shared/types';

import { firestoreServerTimestamp, firestoreArrayUnion } from './utils';

export function firestoreAddMessage(
  conversation_id: string,
  message: Omit<
    MessageDoc,
    'timestamp' | 'message_id' | 'group_id' | 'conversation_id'
  >
) {
  const path = `conversations/${conversation_id}/messages`;
  const docRef = doc(collection(getFirestore(), path));

  const messageDoc: MessageDoc = {
    ...message,
    conversation_id,
    group_id: conversation_id,
    message_id: docRef.id,
    timestamp: firestoreServerTimestamp(),
  };
  const conversationDoc: Partial<ConversationDoc> = {
    uids: firestoreArrayUnion([messageDoc.uid]),
    group_id: conversation_id,
    conversation_id,
    last_message: messageDoc,
    status: 'open',
  };

  const batch = writeBatch(getFirestore());

  const messagePath = `conversations/${conversation_id}/messages/${messageDoc.message_id}`;
  const messageDocRef = doc(getFirestore(), messagePath);
  batch.set(messageDocRef, messageDoc, { merge: true });

  const conversationPath = `conversations/${conversation_id}`;
  const conversationDocRef = doc(getFirestore(), conversationPath);
  batch.set(conversationDocRef, conversationDoc, { merge: true });

  return batch.commit();
}

export function firestoreMessageMarkReadAt({
  conversation_id,
  message_id,
}: Pick<MessageDoc, 'conversation_id' | 'message_id'>) {
  if (!conversation_id || !message_id) return;
  const path = `conversations/${conversation_id}/messages/${message_id}`;
  const docRef = doc(getFirestore(), path);
  const messageDoc: Partial<MessageDoc> = {
    read_at: firestoreServerTimestamp(),
  };
  return setDoc(docRef, messageDoc, { merge: true });
}

export function firestoreListenToMessages(
  conversation_id: string,
  docLimit: number,
  callback: (messages: MessageDoc[]) => void
) {
  const path = `conversations/${conversation_id}/messages`;
  const collectionRef = collection(getFirestore(), path);
  const q = query(collectionRef, orderBy('timestamp', 'desc'), limit(docLimit));
  return onSnapshot(q, querySnapshot => {
    const docs = querySnapshot.docs
      .map(doc => doc.data() as MessageDoc)
      .reverse();
    callback(docs);
  });
}

// ADMIN ONLY

export function firestoreUpdateConversation(
  conversation_id: string,
  conversation: Partial<ConversationDoc>
) {
  const path = `conversations/${conversation_id}`;
  const docRef = doc(getFirestore(), path);
  return setDoc(docRef, conversation, { merge: true });
}

export function firestoreListenToConversationsByStatus(
  status: ConversationDoc['status'],
  docLimit: number = 50,
  callback: (conversations: ConversationDoc[]) => void
) {
  const path = `conversations`;
  const collectionRef = collection(getFirestore(), path);
  const key: keyof ConversationDoc = 'status';
  const q = query(
    collectionRef,
    where(key, '==', status),
    orderBy('last_message.timestamp', 'desc'),
    limit(docLimit)
  );
  return onSnapshot(q, querySnapshot => {
    const docs = querySnapshot.docs.map(doc => doc.data() as ConversationDoc);
    callback(docs);
  });
}

export async function firestoreGetOpenConversationCount() {
  const path = `conversations`;
  const collectionRef = collection(getFirestore(), path);
  const key: keyof ConversationDoc = 'status';
  const value: ConversationDoc['status'] = 'open';
  const q = query(collectionRef, where(key, '==', value));
  const snapshot = await getCountFromServer(q);
  return snapshot.data().count;
}
